import { Button as DSButton, If } from '@redsales/ds'
import { navigate } from 'gatsby'

import './ContentHeader.scss'

const ContentHeader = ({
  title,
  description,
  buttonIcon = '/images/icons/arrow-back.svg',
  icon,
  onClick = () => navigate(-1),
  onTrack,
  content,
}) => {
  const handleClick = () => {
    if (onTrack) {
      onTrack()
    }

    onClick()
  }

  return (
    <div className="content-header">
      <div>
        <DSButton
          variant="link"
          className="content-header__button"
          onClick={handleClick}
        >
          <img src={buttonIcon} aria-hidden="true" />
        </DSButton>

        <If
          condition={icon}
          renderIf={
            <img
              src={icon}
              aria-hidden="true"
              className="content-header__icon"
            />
          }
        />

        <If
          condition={title}
          renderIf={<h1 className="content-header__title">{title}</h1>}
        />
        <If
          condition={description}
          renderIf={
            <p className="content-header__description">{description}</p>
          }
        />
      </div>
      <If condition={content} renderIf={content} />
    </div>
  )
}

export default ContentHeader
