import ContentHeader from 'components/ContentHeader'
import Header from 'components/Header'
import { If } from '@redsales/ds'

import './AppLayout.scss'
import * as classNames from 'classnames'

const AppLayout = ({
  children,
  useHeader = true,
  useContentHeader = true,
  contentHeaderInfos,
  contentClassName,
  containerClassName,
  layoutClassName,
}) => {
  return (
    <>
      <If condition={useHeader} renderIf={<Header />} />
      <main className={classNames('app-layout', layoutClassName)}>
        <div
          className={classNames('app-layout__container', containerClassName)}
        >
          <div className="app-layout__container-content">
            <If
              condition={useContentHeader}
              renderIf={<ContentHeader {...contentHeaderInfos} />}
            />

            <section
              className={classNames('app-layout__content', contentClassName)}
            >
              {children}
            </section>
          </div>
        </div>
      </main>
    </>
  )
}

export default AppLayout
